import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import dividerMobile from './pattern-divider-mobile.svg';
import dividerDesktop from './pattern-divider-desktop.svg';
import diceImage from './icon-dice.svg'
import './App.css';

function App() {
  const API_URL = "https://api.adviceslip.com/advice";

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });


  const [adviceText, setAdviceText] = useState();
  const [adviceNumber, setAdviceNumber] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const setData = () => {
    fetch(API_URL + "/" +  Math.floor(Math.random() * 206))
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setAdviceNumber(result.slip.id);
        setAdviceText(result.slip.advice);
      }
    )
  }

  useEffect(() => {
    setData();
  },[]) 

  return (
    <div className="App">
      <div className='cardContainer'>
        <div className='card'>
        {isLoaded && <div className='adviceNumber'>ADVICE #{adviceNumber}</div>}
        {isLoaded && <div className='adviceText'>"{adviceText}"</div>}
        {isDesktop ? <div><img src={dividerDesktop}></img></div> : <div><img src={dividerMobile}></img></div>}
        <button onClick={setData}><img src={diceImage}></img></button>
        </div>
      </div>
      
      <div className="attribution">
        Challenge by <a href="https://www.frontendmentor.io?ref=challenge" target="_blank">Frontend Mentor</a>. 
        Coded by <a href="#">Santiago Valdez</a>.
      </div>
    </div>
  );
}

export default App;
